import React, {ChangeEvent, FC, useRef, useState} from "react";
import {useRouter} from "next/router";
import CustomSuggestions from "./CustomSuggestions";
import SearchComponent from "./SearchComponent"
import cls from "./customSearch.module.scss"
import {ICurrencies} from "../../MainLayout";
import {IProduct, TranslationsType} from "../../../../../types/homePageTypes";
import {useDispatch} from "react-redux";
import {mobileSearchClose} from "../../../../../store/mobile-menu";
import useDebounce from "../../../../../hooks/useDebounce";

interface IProps {
    selectedLocale: string;
    dbName: string;
    selectedRate: ICurrencies;
    translationsData: TranslationsType;
    backOrderValue: string;
    defaultLangCode: string;
    changePosition: boolean;
    showCart: boolean;
}

const CustomSearch: FC<IProps> = (
    {
        selectedLocale,
        dbName,
        selectedRate,
        translationsData,
        backOrderValue,
        changePosition,
        showCart,
        defaultLangCode
    }
) => {
    const history = useRouter();
    const [query, setQuery] = useState<string>("");
    const [suggestionsOpen, setSuggestionsOpen] = useState<boolean>(false);
    const [hasSuggestions, setHasSuggestions] = useState<boolean>(false);
    const [suggestedProducts, setSuggestedProducts] = useState<Array<IProduct>>([]);
    const wrapper = useRef<HTMLInputElement>(null);
    const dispatch = useDispatch()
    const close = () => {
        dispatch(mobileSearchClose())
        setSuggestionsOpen(false);
    }

    const handleFocus = () => {
        setSuggestionsOpen(true);
    };
    const handleChangeQuery = ({target: {value}}: ChangeEvent<HTMLInputElement>) => {
        setQuery(value);
    }

    const handleChangeQueryReq = useDebounce(async ({target: {value}}: ChangeEvent<HTMLInputElement>) => {
        if (value.length >= 3) {
            const { default: shopApi } = await import("../../../../../api/shop");
            shopApi.getSearchProducts(value, selectedLocale, defaultLangCode, selectedRate)
                .then((response: any) => {
                    setHasSuggestions(true);
                    setSuggestionsOpen(true);
                    setSuggestedProducts(response);
                });
        } else {
            setSuggestedProducts([]);
            setHasSuggestions(false);
        }
    }, 1000);

    const handleBlur = () => {
        setTimeout(() => {
            if (!document.activeElement || document.activeElement === document.body) {
                return;
            }
            if (wrapper.current && !wrapper.current.contains(document.activeElement)) {
                close();
            }
        }, 10);
    };

    const handleKeyDown = async (event: { key: string, preventDefault: () => void }) => {
        if (event.key === "Escape" || event.key === "Enter") {
            close();
        }
        if (event.key === "Enter" && query.length > 0) {
            event.preventDefault();
            await history.push({pathname: `/catalog/search/${query}`});
        }
    };

    const handleSearchButt = async () => {
        if (query.length > 0) {
            close()
            await history.push({pathname: "/catalog/search/" + query});
        }
    }

    const classSuggestionsOpen = {
        "true": cls["search--suggestions-open"],
        "false": ""
    }
    const classHasSuggestions = {
        "true": cls["search--has-suggestions"],
        "false": ""
    }
    const concatClasses = `
        ${cls.search}
        ${cls[`search--location--header`]}
        ${classSuggestionsOpen[`${suggestionsOpen}`]}
        ${classHasSuggestions[`${hasSuggestions}`]}
    `

    return (
        <div className={concatClasses} ref={wrapper} onBlur={handleBlur} style={changePosition? {margin: 0} : {}}>
            <div className={cls.search__body}>
                <SearchComponent
                    setQuery={setQuery}
                    wrapper={wrapper}
                    handleFocus={handleFocus}
                    handleKeyDown={handleKeyDown}
                    suggestedProducts={suggestedProducts}
                    handleSearchButt={handleSearchButt}
                    handleChangeQuery={handleChangeQuery}
                    handleChangeQueryReq={handleChangeQueryReq}
                    close={close}
                    translationsData={translationsData}
                />
                <CustomSuggestions
                    products={suggestedProducts}
                    dbName={dbName}
                    selectedRate={selectedRate}
                    translationsData={translationsData}
                />
            </div>
        </div>
    );
};

export default CustomSearch;
