import cls from "./customHeader.module.scss"
import React, {FC} from "react";
import {ISecondHeader} from "../MainLayout";
import CustomDepartments from "./CustomDepartments";
import CustomSearch from "./CustomSearch";
import CustomIndicatorPanel from "./CustomIndicators/CustomIndicatorPanel";
import CustomIndicatorCart from "./CustomIndicators/CustomIndicatorCart";
import {useSelector} from "react-redux";
import {IState} from "../../../../types/state";

const SecondSection: FC<ISecondHeader> = (
    {
        categories,
        isTwoKings,
        dbName,
        selectedRate,
        selectedLocale,
        translationsData,
        backOrderValue,
        isSocialLinksActive,
        allowCheckoutValue,
        showAccount,
        showWishlist,
        showCart,
        showSearch,
        isMobile,
        defaultLangCode
    }
) => {
    const openSearch = useSelector((state: IState) => state.mobileMenu.searchOpen)

    const classSearchOpen = {
        true: `${cls["mobile-header__search--open"]} ${cls["mobile-header__search"]}`,
        false: cls["mobile-header__search"]
    }

    const hasCategory = {
        "true": <div className={cls["nav-panel__departments"]}>
            <CustomDepartments categories={categories} isTwoKings={isTwoKings} dbName={dbName}/>
        </div>,
        "false": null
    }

    const hasCart = {
        "true": <div className={`${cls["nav-panel__item_row"]}`}>
            <CustomIndicatorCart
                allowCheckoutValue={allowCheckoutValue}
                translationsData={translationsData}
                dbName={dbName}
                selectedRate={selectedRate}
                locale={selectedLocale}
            />
        </div>,
        "false": null
    }

    const hasSearch = {
        "true": <div className={`${cls["site-header__search"]} ${classSearchOpen[`${openSearch}`]}`}
                     style={!showAccount && !showWishlist && !showCart ? {justifyContent: "center"} : {}}
        >
            <CustomSearch
                translationsData={translationsData}
                selectedLocale={selectedLocale}
                dbName={dbName}
                selectedRate={selectedRate}
                backOrderValue={backOrderValue}
                changePosition={!showAccount && !showWishlist && !showCart}
                showCart={showCart}
                defaultLangCode={defaultLangCode}
            />
        </div>,
        "false": null
    }

    return <div className={cls.second_section}>
        <div className={`${cls["nav-panel"]}`}>
            <div className={`${cls["nav-panel__container"]} container`}>
                <div className={`${cls["nav-panel__row"]}`}>
                    {hasCategory[`${!!categories.length}`]}
                    {hasSearch[`${showSearch}`]}
                    <div className={`${cls["nav-panel__indicators"]}`}>
                        <CustomIndicatorPanel
                            isSocialLinksActive={isSocialLinksActive}
                            translationsData={translationsData}
                            selectedLocale={selectedLocale}
                            selectedRate={selectedRate}
                            dbName={dbName}
                            showAccount={showAccount}
                            showWishlist={showWishlist}
                            isMobile={isMobile}
                        />
                        {hasCart[`${showCart}`]}
                    </div>
                </div>
            </div>
        </div>
    </div>
};

export default SecondSection;