import React, { FC, Fragment, useEffect, useMemo, useState } from "react"
import Link from "next/link";
import { useDispatch, useSelector } from "react-redux";
import {IState} from "../../../../../types/state";
import { setAuth, setCustomerGroupID, setToken } from "../../../../../store/customer";
import { getCartDataMount } from "../../../../../store/cart";
import shopApi from "../../../../../api/shop";
import { AddCartToken } from "../../../../../store/token";
import { wishListRemoveAllItems } from "../../../../../store/wishlist";
import CustomIndicatorAccount from "./CustomIndicatorAccount";
import CustomIndicator from "./CustomIndicator";
import CustomFailPanel from "./CustomFailPanel";
import {TranslationsType} from "../../../../../types/homePageTypes";
import {ICurrencies} from "../../MainLayout";
import AccountNavigationMenu from "../../../../account/AccountNavigationMenu";
import { Logout } from "../../../../../svg";
import { useRouter } from "next/router";
import { apiUrlWithStore } from "../../../../../helper";
import cls from "../customHeader.module.scss"

interface IIndicatorPanel {
    isSocialLinksActive: Array<Record<string, string>>;
    translationsData: TranslationsType;
    selectedLocale: string;
    selectedRate: ICurrencies;
    showAccount: boolean
    showWishlist: boolean
    dbName: string;
    isMobile: boolean;
}

const CustomIndicatorPanel: FC<IIndicatorPanel> = (
    {
        translationsData,
        selectedLocale,
        selectedRate,
        isSocialLinksActive,
        dbName,
        showAccount,
        showWishlist,
        isMobile
    }
) => {

    const [personal, setPersonal] = useState<string>('')
    const router = useRouter();
    const dispatch = useDispatch()
    const customer = useSelector((state: IState) => state.customer);
    const {cartToken} = useSelector((state: IState) => state.cartToken) || {}
    const signed = useSelector((state: IState) => state.customer.authenticated);
    const wishlist = useSelector((state: IState) => state.wishlist);
    const wishIndicator = {
        "true": {
            "true": <CustomIndicator
                url="/wishlist"
                value={wishlist.length}
                icon="heartIcon"
                title={translationsData?.WishList}
                className=""
                dropdown=""
                iconWrapperClassName=""
            />,
            "false": <CustomFailPanel translationsData={translationsData}/>
        },
        "false": {
            "true": null,
            "false": null
        }
    }

    const logout = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        dispatch(setAuth(false));
        dispatch(setCustomerGroupID(1))
        dispatch(setToken(""));

        shopApi.getCustomerLogoutToken(customer.token)
            .then(item => {
                if (!item.ok) return;
                shopApi.getCheckoutCartToken()
                    .then(res => res.api_token && dispatch(AddCartToken(res.api_token)))
            })
        dispatch(getCartDataMount(cartToken, {token: ""}, selectedLocale, selectedRate ? selectedRate.code : ""));
        dispatch(wishListRemoveAllItems())
        router.push("/");
    };

    useEffect(() => {
        const abortController = new AbortController();
        const {signal} = abortController;

        if (customer.token) {
            fetch(apiUrlWithStore(`/api/customer/get?token=${customer.token}`), {signal})
                .then((response) => response.json())
                .then((res) => res && setPersonal(`${res.data.first_name} ${res.data.last_name}`))
                .catch((err) => console.error(err));
        }

        return () => abortController.abort();
    }, [customer.token]);

    const links = useMemo(() => (
        [
            {
                title: translationsData.PersonalInformation,
                url: "profile",
                id: 1,
            },
            {
                title: translationsData.MyOrders,
                url: "orders",
                id: 2,
            },
            {
                title: translationsData.DownloadableProducts,
                url: "downloadable-products",
                id: 3,
            },
            {
                title: translationsData.Address,
                url: "addresses",
                id: 4,
            },
            {
                title: translationsData.SignOut,
                url: "logout",
                icon: <Logout/>,
                id: 5,
            }
        ].map((link) => {
            const classes = `${cls["account-nav__item"]} ${router.route.includes(link.url) ? cls["account-nav__item--active"] : ""}`
            const signOutProps = link.url === "logout" ? {onClick: logout, className: cls.logout} : {}
            return (
                <li key={link.id} className={classes}>
                    <Link href={`/account/${link.url}`}>
                        <a {...signOutProps}>
                            {link.icon ? <span>{link.icon}</span> : null}
                            {link.title}
                        </a>
                    </Link>
                </li>
            );
        })
    ), [router.route, router.locale]);
    const handleShowAccountIcon = {
        "true": <div className={`${cls["nav-panel__item_row"]} ${cls["nav-panel__item-custom"]}`}>
            <CustomIndicatorAccount
                isSocialLinksActive={isSocialLinksActive}
                translationsData={translationsData}
                selectedLocale={selectedLocale}
                selectedRate={selectedRate}
                dbName={dbName}
            />
            {!isMobile && signed ?
                <div className={cls["nav-panel__account-nav"]}>
                    <AccountNavigationMenu links={links} username={personal}/>
                </div> : null}
        </div>,
        "false": null
    }

    return (
        <Fragment>
            {/*{searchIndicator}*/}
            {handleShowAccountIcon[`${showAccount}`]}
            <div className={`${cls["nav-panel__item_row"]} ${cls.heartButton}`}>
                {wishIndicator[`${showWishlist}`][`${signed}`]}
            </div>
        </Fragment>
    )
};

export default CustomIndicatorPanel;